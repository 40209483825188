import { useState, useEffect } from "react";
import { Button, Modal, Box, TextField, Select, FormControl, InputLabel, Typography } from "@material-ui/core";
import axios from "axios";
import CancelPresentationIcon from "@material-ui/icons/CancelPresentation";

export default function CloseLoan({ agencyLevel, component, loan_id, approved, bankPayments }) {
  // Component should be responsible for opening a modal prompting bank user to confirm loan closure with a comment or a checkbox for refinancing.
  const [modalOpen, setModalOpen] = useState(false);
  const [options, setOptions] = useState([
    "სრულად დაფარვა",
    "წინსწრებით სრულად დაფარვა",
    "დეფოლტი",
    "რეფინანსირება",
    "პროგრამიდან გასვლა და თანხის დაბრუნება",
    "ჩახსნა",
    "თანადაფინანსების ვადის გასვლა",
    "სესხი არ გაცემულა",
    "შეწყვეტილი"
  ]);
  const [loading, setLoading] = useState(false);
  const [reason, setReason] = useState(options[0]);
  // const [date, setDate] = useState(new Date().toISOString().slice(0, 10));
  const isBankPaymentsAvailable = bankPayments && bankPayments.length > 0;
  const lastPaymentDate = isBankPaymentsAvailable ? bankPayments[bankPayments.length - 1].date.slice(0, 10) : new Date().toISOString().slice(0, 10);
  const [date, setDate] = useState(new Date().toISOString().slice(0, 10));

  useEffect(() => {
    if (bankPayments && bankPayments.length > 0) {
      const lastPaymentDate = bankPayments[bankPayments.length - 1].date.slice(0, 10);
      setDate(lastPaymentDate);
    }
  }, [bankPayments]);

  function handleSubmit() {
    setLoading(true);
    axios
      .post("/agency/application/close_loan", {
        loanID: loan_id,
        component: component,
        reason: reason,
        date: date,
      })
      .then((res) => {
        setLoading(false);
        setModalOpen(false);
        alert(res.data.message);
        window.location.reload();
      })
      .catch((err) => {
        setLoading(false);
        setModalOpen(false);
        alert(err.response.data.message);
      });
  }

  const ClosureModal = () => (
    <Modal open={modalOpen} onClose={() => setModalOpen(false)} aria-labelledby="closure-modal" aria-describedby="closure-modal-description">
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          position: "absolute",
          top: "35%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 550,
          backgroundColor: "white",
          border: "1px solid #000",
          boxShadow: 24,
          p: 4,
          borderRadius: "5px",
        }}
      >
        {/* Allign title label in top left corner, with border bottom */}
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            borderBottom: "1px solid #000",
            marginBottom: "20px",
          }}
        >
          <Typography variant="h6" component="h2" id="closure-modal-description">
            სესხის დახურვა
          </Typography>
        </Box>
        <FormControl styles={{ m: 1, minWidth: 120 }}>
          <InputLabel htmlFor="loan_closure_reason">მიზეზი</InputLabel>
          <Select
            native
            value={reason}
            onChange={(e) => {
              setReason(e.target.value);
            }}
            inputProps={{
              name: "loan_closure_reason",
              id: "loan_closure_reason",
            }}
          >
            {options.map((option) => (
              <option value={option}>{option}</option>
            ))}
          </Select>
        </FormControl>
        <TextField
          id="loan_closure_date"
          label="თარიღი"
          type="date"
          defaultValue={date}
          onChange={(e) => setDate(e.target.value)}
          style={{
            marginTop: 20,
          }}
        />
        {/* Submit button */}
        <Button
          variant="contained"
          color="primary"
          disabled={loading}
          style={{
            marginTop: 20,
          }}
          onClick={handleSubmit}
        >
          დახურვის მოთხოვნა
        </Button>
      </Box>
    </Modal>
  );
  return (
    <>
      <ClosureModal />
      {agencyLevel >= 2 && (
        <Button
          startIcon={<CancelPresentationIcon />}
          style={{
            display: approved ? "" : "none",
            margin: 10,
          }}
          variant="contained"
          color="primary"
          onClick={() => setModalOpen(true)}
        >
          სესხის დახურვა
        </Button>
      )}
    </>
  );
}
