// Payment object looks like this -
// date 2022-07-04T00:00:00.000+00:00
// bank 612ed92874519eaa203504bf
// amount 544
// interest_amount 422
// principal_amount 122
// _id 633267a0dd3f282eb938e104

import { DataGrid } from "@mui/x-data-grid";
import dateFormat from "dateformat";

//Create component showcasing all payments using only material ui.
export default function BankPaymentTable({ rows }) {
  const columns = [
    {
      field: "bank",
      headerName: "ბანკი",
      flex: 2,
      headerAlign: "center",
      align: "center",
      valueGetter: (params) => {
        return params.row.bank.name;
      },
    },
    { field: "amount", headerName: "თანხა", flex: 2, headerAlign: "center", align: "center" },
    { field: "interest_amount", headerName: "პროცენტი", flex: 2, headerAlign: "center", align: "center" },
    { field: "principal_amount", headerName: "ძირი", flex: 2, headerAlign: "center", align: "center" },
    {
      field: "date",
      headerName: "თარიღი",
      flex: 2,
      headerAlign: "center",
      align: "center",
      type: "date",
      valueGetter: (params) => {
        return dateFormat(params.row.date, "yyyy-mm-dd", true);
      },
    },
  ];

  return (
    <div style={{ marginTop: 30, height: 400, width: "100%" }}>
      <DataGrid getRowId={(row) => row._id} rows={rows} columns={columns} pageSize={100} rowsPerPageOptions={[5]} disableColumnMenu hideFooter />;
    </div>
  );
}
