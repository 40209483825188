const htmlTemplates = {
  generateForBank: (data) => {
    const dt = JSON.parse(JSON.stringify(data));
    // console.log('dt', dt);
    return {
      css: '',
      html: `
            <div>
            <style>
            @font-face {
                    font-family: 'Dejavu';
                    src: url('../../../assets/fonts/bpg_dejavu_sans.ttf') format('truetype');
                    font-weight: normal;
                    font-style: italic;
                }
            #customers {
  font-family: Arial, Helvetica, sans-serif;
  width: 100%;
}

#customers td, #customers th {
  font-family: 'DejaVu', sans-serif;
  border: 1px solid #2C5B94;
  padding: 8px;
}
 table {
  font-size: 14px;
  color: black;
  font-family: 'DejaVu', sans-serif;
  border-spacing: 5px; /* Adds space between cell borders */
  border: 1px solid #2C5B94; /* Adds a border around the entire table */
  border-radius: 5px; /* Adds a little curve to the border */
}

#customers tr:nth-child(odd){background-color: inherits;}

#customers tr:hover {background-color: #2C5B94;}

#customers th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: left;
  background-color: #04AA6D;
  color: white;
}
            </style>
<h4 style="text-align:center; padding:0 70px;">სახელმწიფო პროგრამაში "უნივერსალურ ინდუსტრიული ნაწილი" დადასტურებული სესხის/ლიზინგის პროექტი</h4>
<br/>
<table id="customers">
  <tr>
    <td><b>ბენეფიციარი:</b></td>
    <td>
      <div style="display:flex;flex-direction: column; gap:10px">
            <table style="border:1px solid #ddd;  border-radius:2px; border-collapse: collapse;">
                <tbody>
                  <tr>
                    <td  style="border:none; border-bottom:1px solid #ddd;">სამართლებრივი ფორმა:</td>
                    <td  style="border:none; border-bottom:1px solid #ddd; border-left:1px solid #ddd;">${dt?.applicant_information?.legal_form?.name ? dt?.applicant_information?.legal_form?.name : ''}</td>
                  </tr>
                  <tr>
                    <td  style="border:none; border-bottom:1px solid #ddd;">დასახელება:</td>
                    <td  style="border:none; border-bottom:1px solid #ddd; border-left:1px solid #ddd;">${dt?.applicant_information?.name ? dt?.applicant_information?.name : ''}</td>
                  </tr>
                  <tr>
                    <td  style="border:none; border-bottom:1px solid #ddd;">საიდენტიფიკაციო კოდი:</td>
                    <td  style="border:none; border-bottom:1px solid #ddd; border-left:1px solid #ddd;">${dt?.applicant_information?.identification_code ? dt?.applicant_information?.identification_code : ''}</td>
                  </tr>
                </tbody>
        </table>
      </div>
    </td>
  </tr>
  <tr>
    <td><b>სესხის/ლიზინგის ID:</b></td>
    <td>${dt.loan_information?.loan_id}</td>
  </tr>
  <tr>
    <td><b>კომპონენტი:</b></td>
    <td>${dt.program_type?.name}</td>
  </tr>
  <tr>
    <td><b>საკრედიტო საგარანტიო სქემით სარგებლობა:</b></td>
    <td>${dt.credit_guarantee_scheme ? 'მონიშნულია' : 'არ არის მონიშნული'}</td>
  </tr >
  <tr>
  <td><b>თანხა-₾:</b></td>
  <td>${dt?.loan_information?.conditions?.approved_amount?.toLocaleString()}</td>
</tr>
  <tr>
    <td><b>სესხის/ლიზინგის მიზნობრიობა დეტალურად:</b></td>
    <td>${dt.loan_information?.conditions?.purpose?.purpose_detailed}</td>
  </tr>
  <tr>
    <td><b>პროექტის ეკონომიკური აქტივობა:</b></td>
    <td>
        <div style="display:flex;flex-direction: column; gap:10px">
            ${dt.project_information?.economic_activity.map((activity) => (`<table style="border:1px solid #ddd;  border-radius:2px; border-collapse: collapse;">
                <tbody>
                    <tr>
                      <td style="border:none; border-bottom:1px solid #ddd;">კოდი:</td>
                      <td style="border:none; border-bottom:1px solid #ddd; border-left:1px solid #ddd;">${activity.activity_code}</td>
                    <tr>
                    <tr>
                      <td style="border:none; border-bottom:1px solid #ddd;">აღწერა:</td>
                      <td style="border:none; border-bottom:1px solid #ddd; border-left:1px solid #ddd;">${activity.activity_description}</td>
                    <tr>
                </tbody>
            </table>`))}
        </div>
    </td>
  </tr>
  <tr>
    <td><b>საქმიანობა ჯდება 15%-იან გრანტში:</b></td>
    <td>${dt.project_information?.economic_activity.some((activity) => activity.appendix_9 === true)
          ? 'აკმაყოფილებს'
          : 'არ აკმაყოფილებს'}</td>
  </tr>
        <tr>
    <td><b>პროექტის განხორციელების ფაქტობრივი მისამართი:</b></td>
    <td>${dt.project_information?.actual_address?.street}</td>
  </tr>
    <tr>
      <td><b>პროექტის საკადასტრო კოდი:</b></td>
    <td>${dt.project_information?.actual_address?.cadastral_code}</td>
  </tr>
</table >
<br />
<p style="font-family:none; font-size:14px; padding:5px; text-align:right">გავეცანი და ვეთანხმები სსიპ „აწარმოე საქართველოში“ წარდგენილ და დადასტურებულ განაცხადს.</p>
</div >
  `}
  },
}

export default htmlTemplates;