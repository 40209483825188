import "./Mortgage.css";
import React from "react";
import { useState, useEffect } from "react";
import {
  TextField,
  Button,
  InputLabel,
  Select,
  FormControl,
  FormControlLabel,
  Checkbox,
  MenuItem,
  Grid,
  Card,
  CardContent,
  IconButton,
  CardHeader,
} from "@mui/material";
import { Add, Send } from "@material-ui/icons";
import { useForm, useFieldArray } from "react-hook-form";
import { GetData } from "../../../utilities/GetData";
import { PostData } from "../../../utilities/PostData";
// import { Field } from "../../../types";
import axios from "axios";

interface Field {
  group: string;
  name: string;
  label: string;
  type: "text" | "number" | "select" | "checkbox" | "date";
  options?: Array<{ value: string; label: string }>;
  required?: boolean;
  validation?: (value: any) => string | Promise<string>;
  readonly?: boolean;
  value?: number;
  visibility?: (data: any) => boolean;
}

type Municipality = {
  _id: string;
  region: string; // this should be matched with the region
  municipality: string;
};

type Purpose = {
  _id: string;
  name: string;
};

type Attachment = {
  title: string;
  field: string;
  canAdd?: boolean;
  file?: File;
};

interface UploadedAttachments {
  [key: string]: string[];
}

const MortgageNew = ({ editing, mortgageID }: { editing: boolean; mortgageID: string }) => {
  const { register, control, handleSubmit, watch, getValues, setValue, unregister } = useForm();
  const { fields: childrenFields, append: appendChildren, remove: removeChildren } = useFieldArray({ control, name: "children_information" });
  const [purposes, setPurposes] = useState<Array<Purpose>>([]);
  const [mortgageTypes, setMortgageTypes] = useState<Array<Purpose>>([]);
  const [regions, setRegions] = useState<Array<string>>([]);
  const [municipalities, setMunicipalities] = useState<Array<Municipality>>([]);
  const [currencies, setCurrencies] = useState(["GEL", "USD", "EUR"]);
  const [legalForms, setLegalForms] = useState<Array<Purpose>>([]);
  const [marriageStatuses, setMarriageStatuses] = useState(["ოჯახი", "მარტოხელა მშობელი", "ქვრივი"]);
  const [citizenships, setCitizenships] = useState(["საქართველოს მოქალაქე", "სხვა ქვეყნის მოქალაქე"]);
  const [genders, setGenders] = useState(["მამრობითი", "მდედრობითი"]);
  const [banks, setBanks] = useState<Array<Purpose>>([]);
  const [validationErrors, setValidationErrors] = useState<{ [key: string]: string }>({});
  const [attachmentButtons, setAttachmentButtons] = useState([]);
  const [uploadedAttachments, setUploadedAttachments] = useState<UploadedAttachments>({});
  const [attachmentFields, setAttachmentFields] = useState<Array<Attachment>>([
    { title: "შვილის/გერის დაბადების მოწმობა", field: "children_birth_certificate", canAdd: true },
  ]);
  const [isUploading, setIsUploading] = useState(false);
  const [loading, setLoading] = useState(false);

  const formValues = watch();

  useEffect(() => {
    GetData("general_info")
      .then(async (resp) => {
        setPurposes(resp.purposes);
        setMortgageTypes(resp.mortgage_program_types);
        setRegions(resp.regions);
        setMunicipalities(resp.municipalities);
        setLegalForms(resp.legal_forms);
        setBanks(resp.banks);
      })
      .catch((err) => {});
  }, []);

  function fileChangeHandler(event: any, index: number) {
    let newAttachmentFields = [...attachmentFields];
    let newAttachmentField = { ...newAttachmentFields[index] };
    newAttachmentField.file = event.target.files[0];
    newAttachmentFields[index] = newAttachmentField;
    setAttachmentFields(newAttachmentFields);
  }

  function fileSubmitHandler(fieldName: string, index: number) {
    const file = attachmentFields[index].file;

    if (!file) {
      alert("No file uploaded.");
      return;
    }

    const fileSizeInMB = file.size / 1024 / 1024; // Size in MB

    if (fileSizeInMB > 20) {
      alert("ფაილის ზომა აღემატება 20MB-ს.");
      return;
    }

    setIsUploading(true);
    uploadFile(file, fieldName, index);
  }

  function uploadFile(file: File, fieldName: string, index: number) {
    const fileData = new FormData();
    fileData.append(fieldName, file, file.name);

    PostData("bank/application/upload_attachments", fileData, true)
      .then((res) => {
        const newAttachments: any = { ...uploadedAttachments };
        if (newAttachments[fieldName]) {
          newAttachments[fieldName] = [...newAttachments[fieldName], res[fieldName][0]];
        } else {
          newAttachments[fieldName] = res[fieldName];
        }
        setUploadedAttachments(newAttachments);
        setIsUploading(false);
      })
      .catch((err) => {
        alert("ფაილის ატვირთვისას დაფიქსირდა შეცდომა");
        setIsUploading(false);
      });
  }
  async function checkExistingBorrower(private_number: string) {
    let applicationType = mortgageTypes.find((mortgageType) => mortgageType._id === formValues.application_type);
    let response = { error: false, message: "" };
    if (applicationType?.name !== "რეფინანსირება" && applicationType?.name !== "მეორე იპოთეკური სესხი" && !editing)
      response = await PostData("check_mortgage_private_number", { private_number: private_number });
    return response.error ? response.message : "";
  }

  async function checkAdult(birth_date: string) {
    let applicationType = mortgageTypes.find((mortgageType) => mortgageType._id === formValues.application_type);
    let response = { error: false, message: "" };
    if (applicationType?.name !== "რეფინანსირება" && applicationType?.name !== "მეორე იპოთეკური სესხი")
      response = await PostData("check_child_age", { birth_date: birth_date });
    return response.error ? response.message : "";
  }

  useEffect(() => {
    let single_mother_certificate = { title: "მარტოხელა მშობლის სტატუსის დამადასტურებელი დოკუმენტი", field: "single_mother_certificate" };
    let spouse_death_certificate = { title: "მეუღლის გარდაცვალების მოწმობა", field: "spouse_death_certificate" };
    let child_adoption_certificate = { title: "შვილად აყვანის მოწმობა", field: "child_adoption_certificate", canAdd: true };
    let selectedMaritalStatus: string = formValues.borrower_information?.borrower?.marital_status;
    let hasAdoptedChild: boolean = formValues.children_information?.some((child: any) => child.is_adopted);
    if (selectedMaritalStatus?.includes("მარტოხელა")) {
      if (attachmentFields.filter((field) => field.title === single_mother_certificate.title).length === 0) {
        setAttachmentFields([...attachmentFields, single_mother_certificate]);
      }
    }
    if (selectedMaritalStatus?.includes("ქვრივი")) {
      if (attachmentFields.filter((field) => field.title === spouse_death_certificate.title).length === 0) {
        setAttachmentFields([...attachmentFields, spouse_death_certificate]);
      }
    }
    if (hasAdoptedChild) {
      if (attachmentFields.filter((field) => field.title === child_adoption_certificate.title).length === 0) {
        setAttachmentFields([...attachmentFields, child_adoption_certificate]);
      }
    }
    // }, [selectedMaritalStatus, hasAdoptedChild]);
  }, [formValues]);

  // async function checkExistingBorrowerChild(private_number) {
  //   let response = await PostData("check_mortgage_child_private_number", { private_number: private_number });
  //   return response;
  // }

  const fields: Array<Field> = [
    {
      group: "application_type",
      name: "purpose",
      label: "სესხის მიზნობრიობა",
      required: true,
      type: "select",
      options: purposes.map((purpose) => ({
        value: purpose._id,
        label: purpose.name,
      })),
    },
    {
      group: "application_type",
      name: "application_type",
      label: "განაცხადის ტიპი",
      required: true,
      type: "select",
      options: mortgageTypes.map((mortgageType) => ({
        value: mortgageType._id,
        label: mortgageType.name,
      })),
    },
    {
      group: "real_estate_information",
      name: "home_information.region",
      label: "რეგიონი",
      required: true,
      type: "select",
      options: regions.map((region) => ({
        value: region,
        label: region,
      })),
    },
    {
      group: "real_estate_information",
      name: "home_information.municipality",
      label: "მუნიციპალიტეტი",
      required: true,
      type: "select",
      // This should be filtered based on the region
      // watch region and filter municipalities
      options: municipalities
        .filter((municipality) => municipality.region === watch("home_information.region"))
        .map((municipality) => ({
          value: municipality._id,
          label: municipality.municipality,
        })),
    },
    {
      group: "real_estate_information",
      name: "home_information.exact_address",
      label: "მის. (სოფელი/დაბა/ქუჩა/ნომერი)",
      required: true,
      type: "text",
    },
    {
      group: "real_estate_information",
      name: "home_information.cadastral_code",
      label: "საკადასტრო კოდი",
      type: "text",
    },
    {
      group: "real_estate_information",
      name: "home_information.cadastral_code_absence_reason",
      label: "საკადასტრო კოდის არარსებობის მიზეზი",
      type: "text",
      // make visible only if cadastral_code is empty
      visibility: (data) => data.home_information?.cadastral_code === "",
    },
    {
      group: "real_estate_information",
      name: "home_information.market_value",
      label: "საბაზრო ღირებულება",
      required: true,
      type: "number",
    },
    {
      group: "real_estate_information",
      name: "home_information.market_value_currency",
      label: "ვალუტა",
      type: "select",
      options: currencies.map((currency) => ({
        value: currency,
        label: currency,
      })),
    },
    {
      group: "real_estate_information",
      name: "home_information.with_storage",
      label: "შეძენილ იქნება დამატებით სამეურნეო სათავსო(ებ)ი, განთავსებული იმავე საცხოვრებელ სახლში/კომპლექსში",
      type: "checkbox",
      visibility: (data) => {
        let purpose = purposes.find((purpose) => purpose._id === data.purpose);
        return purpose?.name === "ბინის შეძენა";
      },
    },
    {
      group: "realtor",
      name: "home_information.realtor_type",
      label: "უძრავი ქონების რეალიზატორი",
      type: "select",
      options: [
        {
          value: "developer",
          label: "დეველოპერული კომპანია",
        },
        {
          value: "person",
          label: "სხვა პირი",
        },
      ],
    },
    {
      group: "realtor",
      name: "home_information.developer_information.legal_form",
      label: "სამართლებრივი ფორმა",
      type: "select",
      visibility: (data) => data.home_information?.realtor_type === "developer",
      options: (watch("home_information.realtor_type") === "developer"
        ? legalForms.filter((legal_form) => legal_form.name !== "ფიზიკური პირი" && legal_form.name !== "ი/მ")
        : legalForms
      ).map((legal_form) => ({
        value: legal_form._id,
        label: legal_form.name,
      })),
    },
    {
      group: "realtor",
      name: "home_information.other_person_information.legal_form",
      label: "სამართლებრივი ფორმა",
      type: "select",
      visibility: (data) => data.home_information?.realtor_type === "person",
      options: (watch("home_information.realtor_type") === "developer"
        ? legalForms.filter((legal_form) => legal_form.name !== "ფიზიკური პირი" && legal_form.name !== "ი/მ")
        : legalForms
      ).map((legal_form) => ({
        value: legal_form._id,
        label: legal_form.name,
      })),
    },
    {
      group: "realtor",
      name: "home_information.developer_information.name",
      label: "ორგანიზაციის დასახელება",
      type: "text",
      visibility: (data) => {
        const legalForm =
          legalForms.find((legal_form) => legal_form._id === data.home_information?.developer_information?.legal_form) ||
          legalForms.find((legal_form) => legal_form._id === data.home_information?.other_person_information?.legal_form);
        return legalForm?.name !== "ი/მ" && legalForm?.name !== "ფიზიკური პირი" && data.home_information?.realtor_type === "developer";
      },
    },
    {
      group: "realtor",
      name: "home_information.other_person_information.name",
      label: "ორგანიზაციის დასახელება",
      type: "text",
      visibility: (data) => {
        const legalForm =
          legalForms.find((legal_form) => legal_form._id === data.home_information?.developer_information?.legal_form) ||
          legalForms.find((legal_form) => legal_form._id === data.home_information?.other_person_information?.legal_form);
        return legalForm?.name !== "ი/მ" && legalForm?.name !== "ფიზიკური პირი" && data.home_information?.realtor_type === "person";
      },
    },
    {
      group: "realtor",
      name: "home_information.developer_information.identification_number",
      label: "საიდენტიფიკაციო კოდი",
      type: "text",
      // visible only if legal form is not ი/მ or ფიზიკური პირი
      visibility: (data) => {
        const legalForm =
          legalForms.find((legal_form) => legal_form._id === data.home_information?.developer_information?.legal_form) ||
          legalForms.find((legal_form) => legal_form._id === data.home_information?.other_person_information?.legal_form);
        return legalForm?.name !== "ი/მ" && legalForm?.name !== "ფიზიკური პირი" && data.home_information?.realtor_type === "developer";
      },
    },
    {
      group: "realtor",
      name: "home_information.other_person_information.identification_number",
      label: "საიდენტიფიკაციო კოდი",
      type: "text",
      visibility: (data) => {
        return data.home_information?.realtor_type === "person";
      },
    },
    {
      group: "realtor",
      name: "home_information.other_person_information.first_name",
      label: "სახელი",
      type: "text",
      // visible only if legal form is ი/მ or ფიზიკური პირი
      visibility: (data) => {
        const legalForm =
          legalForms.find((legal_form) => legal_form._id === data.home_information?.developer_information?.legal_form) ||
          legalForms.find((legal_form) => legal_form._id === data.home_information?.other_person_information?.legal_form);
        return legalForm?.name === "ი/მ" || legalForm?.name === "ფიზიკური პირი";
      },
    },
    {
      group: "realtor",
      name: "home_information.other_person_information.last_name",
      label: "გვარი",
      type: "text",
      // visible only if legal form is ი/მ or ფიზიკური პირი
      visibility: (data) => {
        const legalForm =
          legalForms.find((legal_form) => legal_form._id === data.home_information?.developer_information?.legal_form) ||
          legalForms.find((legal_form) => legal_form._id === data.home_information?.other_person_information?.legal_form);
        return legalForm?.name === "ი/მ" || legalForm?.name === "ფიზიკური პირი";
      },
    },
    {
      group: "borrower",
      name: "borrower_information.borrower.marital_status",
      label: "მსესხებლის სტატუსი",
      required: true,
      type: "select",
      options: marriageStatuses.map((marriageStatus) => ({
        value: marriageStatus,
        label: marriageStatus,
      })),
    },
    {
      group: "borrower",
      name: "borrower_information.borrower.first_name",
      label: "სახელი",
      required: true,
      type: "text",
    },
    {
      group: "borrower",
      name: "borrower_information.borrower.last_name",
      label: "გვარი",
      required: true,
      type: "text",
    },
    {
      group: "borrower",
      name: "borrower_information.borrower.private_number",
      label: "პ/ნ",
      required: true,
      type: "number",
      validation: async (value) => {
        let response = await checkExistingBorrower(value);
        return response;
      },
    },
    {
      group: "borrower",
      name: "borrower_information.borrower.phone_number",
      label: "საკონტაქტო ნომერი (5xxxxxxxx)",
      required: true,
      type: "text",
    },
    {
      group: "borrower",
      name: "borrower_information.borrower.citizenship",
      label: "მოქალაქეობა",
      required: true,
      type: "select",
      options: citizenships.map((citizenship) => ({
        value: citizenship,
        label: citizenship,
      })),
    },
    {
      group: "borrower",
      name: "borrower_information.borrower.citizenship_country",
      label: "მოქალაქეობა",
      type: "text",
      visibility: (data) => data.borrower_information?.borrower?.citizenship === "სხვა ქვეყნის მოქალაქე",
    },
    {
      group: "borrower",
      name: "borrower_information.borrower.gender",
      label: "სქესი",
      required: true,
      type: "select",
      options: genders.map((gender) => ({
        value: gender,
        label: gender,
      })),
    },
    {
      group: "borrower_spouse",
      name: "borrower_information.borrower_spouse.first_name",
      label: "სახელი",
      required: watch("borrower_information.borrower.marital_status") === "ოჯახი",
      type: "text",
    },
    {
      group: "borrower_spouse",
      name: "borrower_information.borrower_spouse.last_name",
      label: "გვარი",
      required: watch("borrower_information.borrower.marital_status") === "ოჯახი",
      type: "text",
    },
    {
      group: "borrower_spouse",
      name: "borrower_information.borrower_spouse.private_number",
      label: "პ/ნ",
      required: watch("borrower_information.borrower.marital_status") === "ოჯახი",
      type: "number",
      validation: async (value) => {
        let response = await checkExistingBorrower(value);
        return response;
      },
    },
    {
      group: "borrower_spouse",
      name: "borrower_information.borrower_spouse.citizenship",
      label: "მოქალაქეობა",
      required: watch("borrower_information.borrower.marital_status") === "ოჯახი",
      type: "select",
      options: citizenships.map((citizenship) => ({
        value: citizenship,
        label: citizenship,
      })),
    },
    {
      group: "borrower_spouse",
      name: "borrower_information.borrower_spouse.citizenship_country",
      label: "მოქალაქეობა",
      type: "text",
      visibility: (data) => data.borrower_information?.borrower_spouse?.citizenship === "სხვა ქვეყნის მოქალაქე",
    },
    {
      group: "borrower_spouse",
      name: "borrower_information.borrower.is_married",
      label: "იმყოფება რეგისტრირებულ ქორწინებაში",
      required: watch("borrower_information.borrower.marital_status") === "ოჯახი",
      type: "checkbox",
    },
    {
      group: "loan_information",
      name: "loan_information.loan_id",
      label: "სესხის ID",
      required: true,
      type: "text",
    },
    {
      group: "loan_information",
      name: "loan_information.approval_date",
      label: "დამტკიცების თარიღი",
      required: true,
      type: "date",
    },
    {
      group: "loan_information",
      name: "loan_information.amount",
      required: true,
      label: "სესხის მოცულობა (GEL)",
      validation: async (value) => {
        return value > 200000 ? "სესხის მოცულობა არ უნდა იყოს 200,000 ლარზე მეტი" : "";
      },
      type: "number",
    },
    {
      group: "loan_information",
      name: "loan_information.duration",
      label: "სესხის ვადა (თვე)",
      required: true,
      type: "number",
    },
    {
      group: "loan_information",
      name: "loan_information.subsidy_duration_months",
      label: "თანადაფინანსების ვადა",
      type: "number",
      readonly: true,
      // if application type is რეფინანსირება, don't show this field
      visibility: (data) => {
        let applicationType = mortgageTypes.find((mortgageType) => mortgageType._id === data.application_type);
        return applicationType?.name !== "რეფინანსირება";
      },
      // if duration is over 60, set it to 60, otherwise set it to duration
      value: watch("loan_information.duration") > 60 ? 60 : watch("loan_information.duration"),
    },
    {
      group: "loan_information",
      name: "loan_information.borrower_co_financing",
      label: "მსესხებლის თანამონაწილეობა",
      type: "number",
      // if application type is რეფინანსირება, don't show this field
      visibility: (data) => {
        let applicationType = mortgageTypes.find((mortgageType) => mortgageType._id === data.application_type);
        return applicationType?.name !== "რეფინანსირება";
      },
    },
    {
      group: "loan_information",
      name: "loan_information.approved_interest_rate",
      label: "დამტკიცებული % განაკვეთი",
      required: true,
      type: "number",
    },
    {
      group: "refinancing_information",
      name: "refinancing_information.initial_bank",
      label: "ბანკი (საწყისი)",
      required: true,
      type: "select",
      options: banks.map((bank) => ({
        value: bank._id,
        label: bank.name,
      })),
    },
    {
      group: "refinancing_information",
      name: "refinancing_information.loan_id",
      label: "სესხის ID",
      required: true,
      type: "text",
    },
    {
      group: "refinancing_information",
      name: "refinancing_information.first_disbursment_date",
      label: "სესხის პირველი ტრანშის გაცემის თარიღი",
      required: true,
      type: "date",
    },
    {
      group: "refinancing_information",
      name: "refinancing_information.approved_amount",
      label: "დამტკიცებული თანხა (GEL)",
      required: true,
      type: "number",
    },
    {
      group: "refinancing_information",
      name: "refinancing_information.remaining_amount",
      label: "ნაშთი (დარჩენილი ძირითადი თანხა) GEL",
      required: true,
      type: "number",
    },
    {
      group: "refinancing_information",
      name: "refinancing_information.outstanding_amount",
      label: "დარჩენილი ასათვისებელი მოცულობა (GEL) (არსებობის შემთხვევაში)",
      required: true,
      type: "number",
    },
  ];

  useEffect(() => {
    // if field visibility condition is not met, then unregister the field
    fields.forEach((field) => {
      if ((getValues(field.name) || getValues(field.name) === "" || getValues(field.name) === false) && field.visibility && !field.visibility(formValues)) {
        unregister(field.name);
      }
    });
    // do same for groups
    groups.forEach((group) => {
      if (group.visibility && !group.visibility(formValues)) {
        fields
          .filter((field) => field.group === group.name)
          .forEach((field) => {
            // field can be "". in that case it still needs to be unregistered. Also, same applies to false booleans
            if (getValues(field.name) || getValues(field.name) === "" || getValues(field.name) === false) {
              unregister(field.name);
            }
          });
      }
    });
  }, [formValues]);

  useEffect(() => {
    // Append a default child when the component mounts
    appendChildren({ name: "", last_name: "", private_number: "" });
  }, [appendChildren]);

  useEffect(() => {
    if (editing && mortgageID) {
      setLoading(true);
      axios
        .get(`/application/mortgage-application/${mortgageID}`)
        .then((response) => {
          const data = response.data;
          setUploadedAttachments(data.attached_documents);
          const processValue = (key: string, value: any) => {
            if (typeof value === "string" && value.match(/^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{3}Z$/)) {
              // Convert date to 'YYYY-MM-DD'
              const formattedDate = new Date(value).toISOString().split("T")[0];
              setValue(key, formattedDate);
            } else if (Array.isArray(value)) {
              // Handle array fields
              value.forEach((item, index) => {
                Object.keys(item).forEach((subKey) => {
                  processValue(`${key}[${index}].${subKey}`, item[subKey]);
                });
              });
            } else if (value && typeof value === "object") {
              if (value.hasOwnProperty("_id")) {
                // Handle nested objects with '_id'
                setValue(key, value._id);
              } else {
                // Iterate through nested objects
                Object.keys(value).forEach((subKey) => {
                  processValue(`${key}.${subKey}`, value[subKey]);
                });
              }
            } else {
              setValue(key, value);
            }
          };

          Object.keys(data).forEach((key) => {
            processValue(key, data[key]);
          });

          setLoading(false);
        })
        .catch((error) => {
          setLoading(false);
          if (error.response) {
            alert(error.response.data.message);
          } else {
            alert("შეცდომა");
          }
        });
    }
  }, [editing, mortgageID, setValue]);

  interface Group {
    name: string;
    label: string | ((data: any) => string);
    visibility?: (data: any) => boolean;
    hasFieldArray?: boolean;
  }

  function handleBlur(field: Field) {
    if (field.validation) {
      Promise.resolve(field.validation(watch(field.name))).then((error) => {
        setValidationErrors((prevErrors) => ({ ...prevErrors, [field.name]: error }));
      });
    }
  }

  const groups: Array<Group> = [
    {
      name: "application_type",
      label: "განაცხადის ტიპი",
    },
    {
      name: "real_estate_information",
      label: "ინფორმაცია ბინის/სახლის/ასაშენებელი ტერიტორიის შესახებ",
      // If application type name is not რეფინანსირება do not show this group
      visibility: (data) => {
        let applicationType = mortgageTypes.find((mortgageType) => mortgageType._id === data.application_type);
        return applicationType?.name !== "რეფინანსირება";
      },
    },
    {
      name: "realtor",
      label: "უძრავი ქონების რეალიზატორი",
      // If application type name is not რეფინანსირება do not show this group
      // if purpose type name is "სახლის აშენება" also do not show this group
      visibility: (data) => {
        let applicationType = mortgageTypes.find((mortgageType) => mortgageType._id === data.application_type);
        let purpose = purposes.find((purpose) => purpose._id === data.purpose);
        return applicationType?.name !== "რეფინანსირება" && purpose?.name !== "სახლის აშენება";
      },
    },
    {
      name: "borrower",
      label: "ინფორმაცია მსესხებლის შესახებ",
    },
    {
      name: "borrower_spouse",
      label: "მსესხებლის მეუღლე",
      visibility: (data) => data.borrower_information?.borrower?.marital_status !== "მარტოხელა მშობელი",
    },
    {
      name: "children_information",
      label: "არასრულწლოვანი შვილ(ებ)ი/გერ(ებ)ი",
      hasFieldArray: true,
    },
    {
      name: "loan_information",
      // label: "ინფორმაცია დამტკიცებული სესხის შესახებ",
      // watch if refinancing is selected, display "დამტკიცებული რეფინანსირებული სესხის შესახებ ინფორმაცია" otherwise display "ინფორმაცია დამტკიცებული სესხის შესახებ"
      label: (data) => {
        let applicationType = mortgageTypes.find((mortgageType) => mortgageType._id === data.application_type);
        return applicationType?.name === "რეფინანსირება" ? "დამტკიცებული რეფინანსირებული სესხის შესახებ ინფორმაცია" : "ინფორმაცია დამტკიცებული სესხის შესახებ";
      },
    },
    {
      name: "refinancing_information",
      label: "დასარეფინანსირებელი სესხის შესახებ ინფორმაცია",
      visibility: (data) => {
        let applicationType = mortgageTypes.find((mortgageType) => mortgageType._id === data.application_type);
        return applicationType?.name === "რეფინანსირება";
      },
    },
  ];

  const childFields: Array<Field> = [
    { group: "children_information", name: "first_name", label: "სახელი", type: "text", required: true },
    { group: "children_information", name: "last_name", label: "გვარი", type: "text", required: true },
    { group: "children_information", name: "private_number", label: "პ/ნ", type: "number", required: true },
    {
      group: "children_information",
      name: "birth_date",
      label: "დაბადების თარიღი",
      type: "date",
      validation: async (value) => {
        let response = await checkAdult(value);
        return response;
      },
      required: true,
    },
    { group: "children_information", name: "is_adopted", label: "შვილად აყვანილი", type: "checkbox" },
  ];

  if (loading) {
    return <p>Loading</p>;
  }

  return (
    <>
      <form
        onSubmit={handleSubmit((data) => {
          setIsUploading(true);

          // append uploaded attachments to data
          data.attached_documents = uploadedAttachments;

          // RERUN ALL VALIDATIONS BEFORE SUBMIT
          // rerun all validations for all fields. await for all promises to resolve and then submit by calling onSubmit
          Promise.all(
            fields.map((field) => {
              if (field.validation && getValues(field.name)) {
                return field.validation(watch(field.name));
              }
            })
          ).then((errors) => {
            setIsUploading(false);
            if (errors.some((error) => error)) {
              // find field name that has error
              const fieldWithError = fields.find((field, index) => errors[index]);
              const groupWithError = groups.find((group) => group.name === fieldWithError?.group);
              alert(`ფორმის -  ${groupWithError?.label} - ჯგუფში - ${fieldWithError?.label} - ველში ვალიდაციის შეცდომაა`);
            }
            if (errors.every((error) => !error)) {
              const url = "bank/application/submit_mortgage";
              PostData(url, data)
                .then((res) => {
                  alert(res.message);
                })
                .catch((err) => {
                  if (err.response) {
                    alert(err.response.data.message);
                  } else {
                    alert("შეცდომა");
                  }
                });
            }
          });
        })}
      >
        {groups.map(
          (group) =>
            (!group.visibility || group.visibility(formValues)) && (
              <Card elevation={0} key={group.name}>
                <CardContent>
                  <CardHeader // instead of title, use custom styled header
                    title={
                      <b
                        style={{
                          fontSize: "0.9rem",
                          margin: "65px 0 10px 0px",
                          color: "rgb(97, 97, 97)",
                          fontFamily: "Title",
                        }}
                      >
                        {group.label instanceof Function ? group.label(formValues) : group.label}
                      </b>
                    }
                  />
                  <Grid container spacing={4}>
                    {group.hasFieldArray
                      ? childrenFields.map((item, index) => (
                          <React.Fragment key={item.id}>
                            {childFields.map((field) => (
                              <Grid item xs={12} md={4} key={field.name}>
                                {field.type === "text" && (
                                  <TextField
                                    required={field.required}
                                    fullWidth
                                    label={field.label}
                                    variant="outlined"
                                    {...register(`children_information[${index}].${field.name}`)}
                                  />
                                )}
                                {field.type === "number" && (
                                  <TextField
                                    required={field.required}
                                    error={!!validationErrors[field.name]}
                                    helperText={validationErrors[field.name]}
                                    fullWidth
                                    label={field.label}
                                    variant="outlined"
                                    type="number"
                                    {...register(`children_information[${index}].${field.name}`, {
                                      onBlur: () =>
                                        handleBlur({
                                          ...field,
                                          name: `children_information[${index}].${field.name}`,
                                        }),
                                    })}
                                  />
                                )}
                                {field.type === "date" && (
                                  <TextField
                                    required={field.required}
                                    fullWidth
                                    label={field.label}
                                    error={!!validationErrors[`children_information[${index}].${field.name}`]}
                                    helperText={validationErrors[`children_information[${index}].${field.name}`]}
                                    variant="outlined"
                                    type="date"
                                    defaultValue={watch(`children_information[${index}].${field.name}`)}
                                    value={watch(`children_information[${index}].${field.name}`)}
                                    InputLabelProps={{
                                      shrink: true,
                                    }}
                                    {...register(`children_information[${index}].${field.name}`, {
                                      onBlur: () =>
                                        handleBlur({
                                          ...field,
                                          name: `children_information[${index}].${field.name}`,
                                        }),
                                    })}
                                  />
                                )}

                                {field.type === "checkbox" && (
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        checked={
                                          watch(`children_information[${index}].${field.name}`) === "on"
                                            ? true
                                            : watch(`children_information[${index}].${field.name}`)
                                        }
                                        {...register(`children_information[${index}].${field.name}`)}
                                      />
                                    }
                                    label={field.label}
                                  />
                                )}
                              </Grid>
                            ))}
                            <Grid item xs={12} md={12}>
                              <Button
                                onClick={() => {
                                  childFields.forEach((field) => {
                                    if (validationErrors[`children_information[${index}].${field.name}`]) {
                                      setValidationErrors((prevErrors) => {
                                        delete prevErrors[`children_information[${index}].${field.name}`];
                                        return prevErrors;
                                      });
                                    }
                                  });
                                  removeChildren(index);
                                }}
                              >
                                წაშლა
                              </Button>
                            </Grid>
                          </React.Fragment>
                        ))
                      : fields
                          .filter((field) => field.group === group.name)
                          .filter((field) => !field.visibility || field.visibility(watch()))
                          .map((field) => (
                            <Grid item xs={12} md={4} key={field.name}>
                              {field.type === "text" && (
                                <TextField
                                  sx={{
                                    fontFamily: "Title",
                                  }}
                                  fullWidth
                                  label={field.label}
                                  defaultValue={field.value || ""}
                                  variant="outlined"
                                  required={field.required}
                                  {...register(field.name)}
                                />
                              )}
                              {field.type === "number" && (
                                <TextField
                                  error={!!validationErrors[field.name]}
                                  helperText={validationErrors[field.name]}
                                  fullWidth
                                  defaultValue={field.value || ""}
                                  label={field.label}
                                  variant="outlined"
                                  type="number"
                                  inputProps={{ step: "0.01", min: "0" }}
                                  value={field.value}
                                  disabled={field.readonly}
                                  InputLabelProps={field.readonly ? { shrink: true } : {}}
                                  required={field.required}
                                  {...register(field.name, { onBlur: () => handleBlur(field) })}
                                />
                              )}
                              {field.type === "date" && (
                                <TextField
                                  fullWidth
                                  label={field.label}
                                  defaultValue={field.value || ""}
                                  variant="outlined"
                                  type="date"
                                  required={field.required}
                                  InputLabelProps={{
                                    shrink: true,
                                  }}
                                  {...register(field.name)}
                                />
                              )}
                              {field.type === "select" && (
                                <FormControl fullWidth variant="outlined" required={field.required}>
                                  <InputLabel id={`${field.name}-label`}>{field.label}</InputLabel>
                                  <Select labelId={`${field.name}-label`} label={field.label} {...register(field.name)} value={watch(field.name)}>
                                    {field.options?.map((option) => (
                                      <MenuItem key={option.value} value={option.value}>
                                        {option.label}
                                      </MenuItem>
                                    ))}
                                  </Select>
                                </FormControl>
                              )}
                              {field.type === "checkbox" && (
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      // set value to true if field is checked, otherwise set it to false
                                      checked={watch(field.name) === "on" ? true : watch(field.name)}
                                      required={field.required}
                                      {...register(field.name)}
                                    />
                                  }
                                  label={field.label}
                                />
                              )}
                            </Grid>
                          ))}
                  </Grid>
                  {group.hasFieldArray && <Button onClick={() => appendChildren({ name: "", last_name: "", private_number: "" })}>დამატება</Button>}
                </CardContent>
              </Card>
            )
        )}
        <b>ასატვირთი დოკუმენტები</b>
        <>
          <div className="mortgage_attachments">
            {attachmentFields.map((attachment, index) => (
              <div key={index} style={{ marginBottom: "20px" }}>
                <FormControl
                  variant="outlined"
                  sx={{
                    flexDirection: "row",
                    flexWrap: "wrap",
                    alignItems: "center",
                    padding: "15px 0",
                  }}
                >
                  <p>{attachment.title}:</p>
                  {/* Display already uploaded files */}
                  <div>
                    {uploadedAttachments[attachment.field]?.map((file, fileIndex) => (
                      <div key={fileIndex}>{file}</div>
                    ))}
                  </div>

                  {/* File input for new uploads */}
                  <input type="file" onChange={(event) => fileChangeHandler(event, index)} />
                  <Button
                    style={{ marginLeft: "14.625rem" }}
                    color="primary"
                    size="small"
                    variant="outlined"
                    onClick={() => {
                      fileSubmitHandler(attachment.field, index);
                    }}
                  >
                    ატვირთვა
                  </Button>

                  {/* Button to add additional file inputs for this attachment type */}
                  {attachment.canAdd && (
                    <IconButton
                      onClick={() => {
                        setAttachmentFields([...attachmentFields, { ...attachment }]);
                      }}
                    >
                      <Add />
                    </IconButton>
                  )}
                </FormControl>
              </div>
            ))}
          </div>
        </>
        <FormControl fullWidth variant="outlined" sx={{ padding: "15px 0", display: "flex", justifyContent: "center", alignItems: "center" }}>
          <Button
            disabled={isUploading}
            startIcon={<Send />}
            sx={{
              width: "40%",
            }}
            type="submit"
            variant="contained"
            color="primary"
          >
            გაგზავნა
          </Button>
        </FormControl>
      </form>
    </>
  );
};

export default MortgageNew;
